import { Field, Formik } from "formik"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import NonSollicitationService from "../../services/nonSollicitationService"
import NonSolicitationPerson from "./nonSolicitationPerson"
import QuotationAdresse from "../quotationAdresse"
import NonSollicitationMapper from "../../services/nonSollicitationMapper"
import NonSollicitationValidation from "./nonSollicitationValidation"
import Alert from 'react-bootstrap/Alert'

class NonSolicitationForm extends React.Component {
  params
  state = {
    initVal: initialValues,
    error: false,
    updated: false
  }
  horaireTel = [
    { id: "09:00", libelle: "09:00" },
    { id: "10:00", libelle: "10:00" },
    { id: "11:00", libelle: "11:00" },
    { id: "12:00", libelle: "12:00" },
    { id: "13:00", libelle: "13:00" },
    { id: "14:00", libelle: "14:00" },
    { id: "15:00", libelle: "15:00" },
    { id: "16:00", libelle: "16:00" },
    { id: "17:00", libelle: "17:00" },
    { id: "18:00", libelle: "18:00" },
  ]
  componentDidUpdate(prevProps) {
    if (
      this.props.regimeAssurance &&
      this.props.regimeAssurance !== prevProps.regimeAssurance
    ) {
      this.props.regimeAssurance.data.forEach(e => {
        e.id = e.libelle
      })
      this.setState({ regimeAssurance: this.props.regimeAssurance })
    }
  }
  handleHoraireDebutChange = (value, setFieldValue) => {
    setFieldValue("debutPlageHoraire", value)
  }
  handleHoraireFinChange = (value, setFieldValue) => {
    setFieldValue("finPlageHoraire", value)
  }

  componentDidMount() {
    this.params = this.props.params
    NonSollicitationService.getCoordonnees(this.params).then(coordonees => {
      NonSollicitationService.getSouhaits(this.params).then(souhaits => {
        this.setState({
          initVal: NonSollicitationMapper.getInitValues(
            souhaits.data,
            coordonees.data
          ),
        })
      })
    })
  }

  saveNonSolicitation(values){
    NonSollicitationService.saveCoordonnees(this.params, values).then(coordonnees =>{
      NonSollicitationService.saveSouhaits(this.params, values).then(souhaits => {
        window.scrollTo(0, 0); this.setState({updated: "Votre demande a été prise en compte"})
      }).catch(()=>{    window.scrollTo(0, 0); this.setState({error: "La sauvegarde de vos préférences n'a pu être effectuée veuillez réessayer plus tard"})})
    }).catch(()=>{    window.scrollTo(0, 0); this.setState({error: "La sauvegarde de vos préférences n'a pu être effectuée veuillez réessayer plus tard"})})

  }
  constructor(props) {
    super(props)
    this.params = this.props.params
  }

  render() {
    return (
      <div>
        <Row>
          {this.state.error ? (
              <Col xs={{span:10, offset:1}} md={{span:6, offset:3}}className={'alert-non-sollicitation'} >
                {" "}
                <Alert variant={'danger'}>
                  {this.state.error}
                </Alert>
              </Col>
          ) : (
              ""
          )}
          {this.state.updated ? (
              <Col xs={{span:10, offset:1}} md={{span:6, offset:3}} className={'alert-non-sollicitation'} >
                {" "}
                <Alert variant={'success'}>
                  {this.state.updated}
                </Alert>
              </Col>
          ) : (
              ""
          )}
        </Row>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.initVal}
          validationSchema={NonSollicitationValidation}
          onSubmit={(values, actions) => {
            this.saveNonSolicitation(values);
            actions.setSubmitting(false)
          }}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Container className="non-solicitation-form">
                <h2> Choisir mes contacts avec la MGC</h2>
                <h3>
                  Informations communiquées par voie électronique ou tout autre
                  support durable
                </h3>
                <Row>
                  <Col>
                    <p>
                      Vous recevrez des informations de la part de la Mutuelle
                      sur l’adresse e-mail que vous nous communiquez.
                    </p>
                  </Col>
                </Row>
                <Row className={"checkbox no-margin"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="dematerialisationDecompte"
                        type="checkbox"
                        component="input"
                        checked={props.values.dematerialisationDecompte}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Si vous ne souhaitez pas recevoir vos décomptes de
                      remboursement par voie électronique, cochez cette case
                    </label>
                  </Col>
                </Row>
                <Row className={"checkbox"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="dematerialisationRm"
                        type="checkbox"
                        component="input"
                        checked={props.values.dematerialisationRm}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Sachez que la Mutuelle se réserve le droit de vous
                      notifier les modifications des statuts et du règlement
                      mutualiste au moyen de tout support durable autre que le
                      papier (votre espace adhérent, e-mail…). Si vous ne
                      souhaitez pas être informé(e) de ces modifications par
                      voie dématérialisée, cochez cette case
                    </label>
                  </Col>
                </Row>
                <Row className={"checkbox no-margin"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="newsletter"
                        type="checkbox"
                        component="input"
                        checked={props.values.newsletter}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Si vous ne souhaitez pas recevoir nos newsletter par voie
                      électronique, cochez cette case
                    </label>
                  </Col>
                </Row>
                <Row className={"checkbox no-margin"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="offresMGC"
                        type="checkbox"
                        component="input"
                        checked={props.values.offresMGC}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Si vous ne souhaitez pas recevoir nos promotions et
                      sollicitations par voie électronique, cochez cette case
                    </label>
                  </Col>
                </Row>
                <div className="row">
                  <h3 className="col-11">Consentement de l’adhérent</h3>
                </div>
                <Row className={"checkbox"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="jeuxConcoursMGC"
                        type="checkbox"
                        component="input"
                        checked={props.values.jeuxConcoursMGC}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Si vous souhaitez recevoir, y compris par voie
                      électronique, des informations sur les événements et les
                      jeux-concours organisés par la MGC, cochez cette case
                    </label>
                  </Col>
                </Row>
                <Row className={"checkbox"}>
                  <Col xs={12}>
                    <label>
                      <Field
                        name="offresPartenaires"
                        type="checkbox"
                        component="input"
                        checked={props.values.offresPartenaires}
                      />
                      <span className="cr">
                        <i className="cr-icon fa fa-check" />
                      </span>
                      Si vous souhaitez recevoir, y compris par voie
                      électronique, les offres de nos partenaires proposées par
                      la MGC (prévoyance…), cochez cette case
                    </label>
                  </Col>
                </Row>
                <h3>Mes coordonnées</h3>
                <Row>
                  <NonSolicitationPerson data={props} />
                </Row>
                <Row>
                  <Col xs={12} className={'plage-horaire'}>
                    Plage horaire à privilégier pour les appels :
                  </Col>
                  <Col xs={11}>
                    <div className={"horaire-non-solicitation"}>
                      <label htmlFor="debutPlageHoraire" className={'de'}>De :</label>
                      <Field
                        name={"debutPlageHoraire"}
                        component="select"
                        id={"debutPlageHoraire"}
                        className="form-control"
                        onChange={e => {
                          this.handleHoraireDebutChange(
                            e.target.value,
                            props.setFieldValue
                          )
                        }}
                      >
                        {this.horaireTel.map(e => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.libelle}
                            </option>
                          )
                        })}
                      </Field>
                      <label htmlFor="'finPlageHoraire">à :</label>
                      <Field
                        name={"finPlageHoraire"}
                        component="select"
                        id={"finPlageHoraire"}
                        className="form-control"
                        onChange={e => {
                          this.handleHoraireFinChange(
                            e.target.value,
                            props.setFieldValue
                          )
                        }}
                      >
                        {this.horaireTel.map(e => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.libelle}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                  </Col>
                  <div className="col-1 icon-horaire">
                    <span
                      className={
                        props.errors.plageHoraire
                          ? "fa fa-times text-danger fa-2x fa"
                          : "fa-check text-success fa-2x fa"
                      }
                    />
                  </div>
                </Row>
                <Row>
                  <Col xs={12}>
                    <QuotationAdresse
                      errors={props.errors}
                      dirty={true}
                      setFieldValue={props.setFieldValue}
                      values={props.values}
                      withoutOffset={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="ptop"
                    xs={{ span: 10, offset: 2 }}
                    lg={{ span: 7, offset: 3 }}
                  >
                    <button
                      type="submit"
                      className={` ${
                          Object.keys(props.errors).length !==0 ? "btn-disabled" : "btn-submit"
                      }`}
                    >
                      VALIDER
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        />
      </div>
    )
  }
}

export default NonSolicitationForm

let initialValues = {
  civilite: "",
  nom: "",
  prenom: "",
  dateNaissance: "",
  numeroStarweb: null,
  appartement: null,
  batiment: null,
  adresseNumero: "",
  adresseNumeroLatin: null,
  adresseComplementAlpha: null,
  adresseTypeVoie: null,
  adresseVoie: "",
  adresseLieuDit: null,
  adresseCodePostal: "",
  adresseVille: "",
  adressePays: "",
  telephoneMobile: null,
  telephoneFixe: null,
  email: "",
  debutPlageHoraire: "",
  finPlageHoraire: "",
  vieContratCourrier: "",
  vieContratTelephone: "",
  vieContratMessaging: "",
  vieContratMail: '',
  offresMGCCourrier: '',
  offresMGCTelephone: '',
  offresMGCMessaging: '',
  offresMGCMail: '',
  offresPartenairesCourrier: '',
  offresPartenairesTelephone: '',
  offresPartenairesMessaging: '',
  offresPartenairesMail: '',
  gestionContratCourrier: '',
  gestionContratTelephone: '',
  gestionContratMessaging: '',
  gestionContratMail: '',
  gestionDecompteCourrier: '',
  gestionDecompteMail: '',
  dematerialisationDecompte: '',
  dematerialisationRm: '',
  newsletter: '',
  offresMGC: '',
  jeuxConcoursMGC: '',
  offresPartenaires:''
}
