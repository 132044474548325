export default class NonSollicitationMapper {
    static getInitValues(souhaits, coordonees) {
        return {
            civilite: coordonees.civilite,
            nom: coordonees.nom,
            prenom: coordonees.prenom,
            dateNaissance: coordonees.dateNaissance,
            numeroStarweb: coordonees.numeroStarweb,
            appartement: coordonees.adresse1,
            batiment: coordonees.adresse2,
            adresseNumero: coordonees.numvoie,
            adresseNumeroLatin: coordonees.numlatinvoie,
            adresseComplementAlpha: coordonees.compalpha,
            adresseTypeVoie: coordonees.typevoie,
            adresseVoie: coordonees.libellevoie,
            adresseLieuDit: coordonees.adresse4,
            adresseCodePostal:  coordonees.codePostal,
            adresseVille: coordonees.ville,
            adressePays: coordonees.pays,
            adressePaysAutocomplete: coordonees.pays,
            telephoneMobile: coordonees.telephoneMobile,
            telephoneFixe: coordonees.telephoneFixe,
            email: coordonees.email,
            debutPlageHoraire: souhaits.debutPlageHoraire,
            finPlageHoraire: souhaits.finPlageHoraire,
            offresMGC: (souhaits.vieContratCourrier && souhaits.vieContratTelephone && souhaits.vieContratMessaging && souhaits.vieContratMail) ?  false : true,
            jeuxConcoursMGC: (souhaits.offresMGCCourrier && souhaits.offresMGCTelephone	 &&  souhaits.offresMGCMessaging	 && souhaits.offresMGCMail	) ?  true : false,
            offresPartenaires: (souhaits.offresPartenairesCourrier && souhaits.offresPartenairesTelephone && souhaits.offresPartenairesMessaging && souhaits.offresPartenairesMail	) ?  true : false,
            gestionContratCourrier: souhaits.gestionContratCourrier,
            gestionContratTelephone: souhaits.gestionContratTelephone,
            gestionContratMessaging: souhaits.gestionContratMessaging,
            gestionContratMail: souhaits.gestionContratMail,
            gestionDecompteCourrier: souhaits.gestionDecompteCourrier,
            gestionDecompteMail: souhaits.gestionDecompteMail,
            dematerialisationDecompte: !souhaits.dematerialisationDecompte,
            dematerialisationRm: !souhaits.dematerialisationRm,
            newsletter: !souhaits.newsletter,
        }
    }
    static coordoneesToDTO(coordonees){
        return {
            civilite: coordonees.civilite,
            nom: coordonees.nom,
            prenom: coordonees.prenom,
            dateNaissance: coordonees.dateNaissance,
            numeroStarweb: coordonees.numeroStarweb,
            adresse1: coordonees.appartement,
            adresse2: coordonees.batiment,
            numvoie : coordonees.adresseNumero,
            numlatinvoie: coordonees.adresseNumeroLatin,
            compalpha: coordonees.adresseComplementAlpha || "",
            typevoie: coordonees.adresseTypeVoie,
            libellevoie : coordonees.adresseVoie,
             adresse4: coordonees.adresseLieuDit,
             codePostal: coordonees.adresseCodePostal,
             ville: coordonees.adresseVille,
             pays: coordonees.adressePays,
            telephoneMobile: coordonees.telephoneMobile,
            telephoneFixe: coordonees.telephoneFixe,
            email: coordonees.email,
        }
    }
    static souhaitsToDTO(souhaits){
        return {
               debutPlageHoraire: souhaits.debutPlageHoraire,
               finPlageHoraire: souhaits.finPlageHoraire,
               vieContratCourrier: !souhaits.offresMGC,
               vieContratTelephone: !souhaits.offresMGC,
               vieContratMessaging: !souhaits.offresMGC,
               vieContratMail: !souhaits.offresMGC,
               offresMGCCourrier: souhaits.jeuxConcoursMGC,
               offresMGCTelephone : souhaits.jeuxConcoursMGC,
               offresMGCMessaging: souhaits.jeuxConcoursMGC,
               offresMGCMail: souhaits.jeuxConcoursMGC,
               offresPartenairesCourrier: souhaits.offresPartenaires,
               offresPartenairesTelephone: souhaits.offresPartenaires,
               offresPartenairesMessaging: souhaits.offresPartenaires,
               offresPartenairesMail: souhaits.offresPartenaires,
               gestionContratCourrier: true,
               gestionContratTelephone: true,
               gestionContratMessaging: true,
               gestionContratMail: true,
               gestionDecompteCourrier: souhaits.gestionDecompteCourrier,
               gestionDecompteMail: souhaits.gestionDecompteMail,
               dematerialisationDecompte: !souhaits.dematerialisationDecompte,
               dematerialisationRm: !souhaits.dematerialisationRm,
               newsletter: !souhaits.newsletter,
        }
    }
}