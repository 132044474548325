import React from "react"
import Layout from "../components/layout"
import NonSolicitationForm from "../components/nonSolicitation/nonSolicitationForm"
import queryString from "query-string"

class MesPreferences extends React.Component {
  params
  constructor(props) {
    super(props)
    let url = this.props.location.search
    this.params = queryString.parse(url)
    this.state = {
      regimeAssurance: null,
      situationProfessionelle: null,
      thisStep: 0,
    }
  }

  render() {
    const metaRobots = [
      {
        name: "robots",
        content: "noindex",
      },
    ]
    return (
      <Layout titleSeo="Non-solicitation" meta={metaRobots}>
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                    Mes préférences
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <section className="pageHeader callback">
          <div className="quotation-title">
            <h1 className="pageHeaderTitle ">Mes préférences</h1>
          </div>
        </section>
        <section className="non-solicitation">
          <div>
            <NonSolicitationForm params={this.params} />
          </div>
        </section>
      </Layout>
    )
  }
}

export default MesPreferences
