import * as Yup from "yup"

const NonSollicitationValidation =
    Yup.object().shape({
        nom: Yup.string().trim().required("nom is required"),
        prenom: Yup.string().trim().required("prenom is required"),
        childrenChecked: Yup.boolean(),
        civilite: Yup.string()
            .matches(/(monsieur|madame)/, { excludeEmptyString: false })
            .required("civilite is required"),
        email: Yup.string().email().required("email is required").nullable(),
        telephoneMobile: Yup.string()
            .matches(/^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/, { excludeEmptyString: true }).nullable(),
        telephoneFixe: Yup.string().matches(/^(\\+33|0|0033)[0-9]{9}$/, {
            excludeEmptyString: true,
        }).nullable(),
        adresseLieuDit: Yup.string().nullable(),
        appartement: Yup.string().nullable(),
        batiment: Yup.string().nullable(),
        adresseNumero: Yup.string().nullable(),
        adresseNumeroLatin: Yup.string().nullable(),
        adresseTypeVoie:  Yup.string().required("adresseTypeVoie is required"),
        adresseVoie:  Yup.string().required("adresseVoie is required"),
        adresseCodePostal: Yup.string().required("adresseCodePostal is required"),
        adressePays: Yup.string().required("adressePays is required"),
        adresseVille: Yup.string().required("adresseVille is required"),
        adresseComplementAlpha: Yup.string().matches(/^[A-Z]{1}$/, {
            excludeEmptyString: true,
        }).nullable(),
        debutPlageHoraire: Yup.string(),
        finPlageHoraire: Yup.string(),
    }).test("test", function(value) {
        const { finPlageHoraire, debutPlageHoraire } = value
        return (
            checkHoraire(finPlageHoraire, debutPlageHoraire) ||
            this.createError({
                path: "plageHoraire",
                message: "L'heure de fin ne peut pas etre antérieure  à celle de debut",
            })
        )
    })

export default NonSollicitationValidation

function checkSelect(val) {
    if (val !== "null" && val) return val
    return false
}
function checkHoraire (finPlageHoraire,debutPlageHoraire){
  let delta =   finPlageHoraire.substring(0, 2) - debutPlageHoraire.substring(0, 2);
    if (delta > 0) {
        return true
    }
    return false
}

