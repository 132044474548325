import Axios from "axios"
import NonSollicitationMapper from "./nonSollicitationMapper"
import { MgcgestionTokenService } from "../services/mgcgestionTokenService"

export default class NonSollicitationService {
  
  static getSouhaits(values) {
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/edeal/souhaits?type=${
      values.type
    }&id=${values.id}`
/*    URL = `http://localhost:8080/api/edeal/souhaits?type=${values.type}&id=${
      values.id
    }`*/
    if (values.id && values.type) {
      return Axios.get(URL, MgcgestionTokenService.getToken())
    } else {
      return null
    }
  }

  static getCoordonnees(values) {
    let URL = `${
      process.env.GATSBY_MGCGESTION_API_URL
    }/edeal/coordonnees?type=${values.type}&id=${values.id}`
/*    URL = `http://localhost:8080/api/edeal/coordonnees?type=${values.type}&id=${
      values.id
    }`*/
    if (values.id && values.type) {
      return Axios.get(URL, MgcgestionTokenService.getToken())
    } else {
      return null
    }
  }

  static saveCoordonnees(param, values) {
    let coordonneesDTO = NonSollicitationMapper.coordoneesToDTO(values)
    let URL = `${
      process.env.GATSBY_MGCGESTION_API_URL
    }/edeal/coordonnees?type=${param.type}&id=${param.id}`
/*    URL = `http://localhost:8080/api/edeal/coordonnees?type=${param.type}&id=${
      param.id
    }`*/
    {
      return Axios.post(URL, coordonneesDTO, {
        headers: { authorization: this.TOKEN },
      })
    }
  }

  static saveSouhaits(param, values) {
    let souhaits = NonSollicitationMapper.souhaitsToDTO(values)
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/edeal/souhaits?type=${
      param.type
    }&id=${param.id}`
/*    URL = `http://localhost:8080/api/edeal/souhaits?type=${param.type}&id=${
      param.id
    }`*/
    {
      return Axios.post(URL, souhaits, MgcgestionTokenService.getToken())
    }
  }
}
