import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import MgcTextField from "../mgcTextField"
import { Field } from "formik"
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"
class NonSolicitationPerson extends React.Component {
    render() {
        const {
            errors,
            dirty,
            values,
            setFieldValue,
            touched,
        } = this.props.data

        return (
            <Container className={'non-sollicitation-person'}>
                <div className="question-y-n">
                    <div className="quotation-radio">

                        <MgcRadioToggleButtonGroup
                            id={"civilite"}
                            error={errors.civilite}
                            touched={touched.civilite}
                            value={values.civilite}
                            dirty={true}
                            withoutOffset={true}
                        >
                            <Field
                                component={MgcRadioToggleButton}
                                name={"civilite"}
                                id="monsieur"
                                label={"Monsieur"}
                                error={errors.civilite}
                                disabled={true}
                            />
                            <Field
                                component={MgcRadioToggleButton}
                                name={"civilite"}
                                id="madame"
                                label={"Madame"}
                                disabled={true}
                            />
                        </MgcRadioToggleButtonGroup>
                    </div>
                </div>
                <Row>
                    <Col xs={12}>
                        <MgcTextField
                            dirty={true}
                            errors={errors}
                            name={"nom"}
                            placeholder="Nom"
                            className="form-control"
                            withoutOffset={true}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <MgcTextField
                            dirty={true}
                            errors={errors}
                            name={"prenom"}
                            placeholder="Prénom"
                            className="form-control"
                            withoutOffset={true}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <MgcTextField
                            dirty={true}
                            errors={errors}
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            withoutOffset={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <MgcTextField
                            dirty={true}
                            errors={errors}
                            name="telephoneMobile"
                            placeholder="N° de téléphone mobile"
                            className="form-control"
                            withoutOffset={true}
                        />
                    </Col>
                    <Col xs={12}>
                        <MgcTextField
                            dirty={true}
                            errors={errors}
                            name="telephoneFixe"
                            placeholder="Autre N° de téléphone"
                            className="form-control"
                            withoutOffset={true}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default NonSolicitationPerson
